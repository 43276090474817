<template>
  <div class="p-4" :class="[size, alignment]" style="width: 100%;">{{ block.text }}</div>
</template>

<script>
export default {
  components: {},
  props: {
    block:{
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    size(){
      switch(this.block.size){
        case 'xsmall':
          return 'is-size-7';
        case 'small':
          return 'is-size-6';
        case 'medium':
          return 'is-size-5';
        case 'large':
          return 'is-size-4';
        case 'xlarge':
          return 'is-size-3'
        default:
          return 'is-size-5';
      }
    },
    alignment(){
      switch(this.block.alignment){
        case 'left':
          return 'has-text-left';
        case 'center':
          return 'has-text-centered';
        case 'right':
          return 'has-text-right';
        default:
          return '';
      }
    }
  },
  methods: {},
  mounted() {
    //
  },
};
</script>
